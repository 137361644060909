require("@rails/ujs").start();
require("turbolinks").start();
import './pagy.js.erb'
import './site_mobile/init.js'






