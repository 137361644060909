import 'slick-carousel'

function initSliderNews() {
  const $sliderNews = $('.js-slider-news');
  if (!$sliderNews.length) {
    return
  }
  $sliderNews.slick({
      dots: false,
      arrows: false,
      infinite: true,
      swipeToSlide: true,
      mobileFirst: true,
      responsive: [
          {
              breakpoint: 767,
              settings: {
                  slidesToShow: 2,
              }
          },
      ]

  });

  document.addEventListener('turbolinks:before-cache', () => {
      const $sliderNews = $('.js-slider-news');
      if (!$sliderNews.length) {
          return
      }
      $sliderNews.slick('unslick')
  })
}
function initSliderChannels() {
  const $sliderChannels = $('.js-slider-channels');
  if (!$sliderChannels.length) {
    return
  }
    $sliderChannels.slick({
      dots: false,
      arrows: false,
      infinite: true,
      swipeToSlide: true,
      mobileFirst: true,
      responsive: [
          {
              breakpoint: 767,
              settings: {
                  slidesToShow: 2,
              }
          },
      ]

  });

  document.addEventListener('turbolinks:before-cache', () => {
      const $sliderChannels = $('.js-slider-channels');
      if (!$sliderChannels.length) {
          return
      }
      $sliderChannels.slick('unslick')
  })
}

function initSliders() {
  initSliderNews();
  initSliderChannels();
}

export default initSliders
